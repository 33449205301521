import React, {useState} from 'react';
import './nav.css';
import {AiOutlineHome, AiOutlineContacts} from 'react-icons/ai';
import {FiUser} from 'react-icons/fi';
import {BsBook} from 'react-icons/bs';
import {RiServiceLine} from 'react-icons/ri';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  return (
    <nav>
      {/* eslint-disable-next-line */}
      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome /></a>
      <a href='#about' onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><FiUser /></a>
      <a href='#experience' onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><BsBook /></a>
      <a href='#services' onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><RiServiceLine /></a>
      <a href='#contact' onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><AiOutlineContacts /></a>
    </nav>
  )
}

export default Nav